var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Template LAF"}},[(!_vm.detail)?_c('vs-button',{staticClass:"mb-2",on:{"click":_vm.handleCreateNew}},[_vm._v("Create Template LAF")]):_vm._e(),_c('div',{staticClass:"vx-row mb-12"},[(!_vm.detail)?_c('div',{class:[
      _vm.detail ? _vm.detailShow + '  w-full mb-base' : '',
      _vm.detailHide,
    ]},[_c('div',{staticClass:"tab-text"},[_c('create',{key:_vm.componentKey,attrs:{"data-selected":_vm.selectedTamplate,"detail-edit":_vm.detailEdit},on:{"open_upload":_vm.handleUpload}})],1)]):_vm._e(),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
        _vm.detail ? _vm.detailShow + '  w-full mb-base' : '',
        _vm.detailHide,
      ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close ")])],1),_c('form-create',{attrs:{"data-selected":_vm.selectedTamplate},on:{"close":_vm.handleClose,"close-edit":_vm.handleCloseToEdit}})],1)],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }