<template>
  <div>
    <!-- main page -->
    <div class="vx-row mb-12">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    this.dataEnd == 0 ? 0 : this.limitShow * (this.isActive - 1) + 1
                  }}
                  - {{ this.limitShow * (this.isActive - 1) + dataEnd }} of
                  {{ this.recordsTotal }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in limits"
                  v-bind:key="item"
                  @click="changeLimit(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div class="mx-auto"></div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="flex flex-wrap items-center justify-between">
              <vs-input
                class="mb-4 md:mb-0 mr-4 w-full"
                v-model="search"
                placeholder="Search by : Budget Code, Currency Code, or Description"
              />
            </div>
          </div>
        </div>

        <vs-table :data="dataBudget" stripe border>
          <template slot="thead">
            <vs-th>Budget</vs-th>
            <vs-th>Value/Used</vs-th>
            <vs-th>Validity</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="idx" v-for="(dt, idx) in data">
              <vs-td style="width: 20%">
                Code : {{ dt.Code }}<br />
                Description : {{ dt.Description }}<br />
                Type : {{ dt.Type == 1 ? "Quantity" : "Value" }}<br />
                Currency : {{ dt.CurrencyCode }}
              </vs-td>
              <vs-td>
                Value : {{ formatCurrency(dt.Value) }} <br />
                Used : {{ formatCurrency(dt.Used) }}
              </vs-td>
              <vs-td>
                From : {{ dateFormat(dt.ValidFrom) }} <br />
                To : {{ dateFormat(dt.ValidTo) }}
              </vs-td>
              <vs-td>
                <feather-icon
                  v-if="(getTab() == TAB_NEW || getTab() == TAB_REJECTED)&&this.$store.getters['user/hasPermissions']('edit')"
                  title="Release"
                  icon="SendIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click="clickSendApproval(dt)"
                />
                
                <feather-icon
                  v-if="getTab() == TAB_APPROVED&&this.$store.getters['user/hasPermissions']('edit')"
                  title="In-Active"
                  icon="XIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click="clickInActive(dt)"
                />

                <feather-icon
                  v-if="getTab() == TAB_APPROVED&&this.$store.getters['user/hasPermissions']('edit')"
                  title="Ammend"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click="clickEdit(dt, 'ammend')"
                />
                <feather-icon
                  v-if="(getTab() == TAB_NEW || getTab() == TAB_REJECTED)&&this.$store.getters['user/hasPermissions']('edit')"
                  title="Edit"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click="clickEdit(dt, 'normal')"
                />
                <feather-icon
                  v-if="getTab() != TAB_NEW && getTab() != TAB_REJECTED"
                  title="Show"
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click="clickView(dt)"
                />
                <feather-icon
                  v-if="getTab() == TAB_NEW&&this.$store.getters['user/hasPermissions']('edit')"
                  title="Cancel"
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                  @click="clickDelete(dt)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <vs-pagination
          style="padding-top: 5px"
          :total="maxPage"
          v-model="currentPage"
          :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'"
          :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"
        />
      </div>
    </div>
    <!--
        <vs-popup fullscreen title="Sales Order Lines" :active.sync="detail">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <detailSelectedBudget :selectedBudget="selectedBudget"></detailSelectedBudget>
                </div>
            </div>
        </vs-popup>
        -->
  </div>
</template>

<script>
// import detailSelectedBudget from "../component/detail_selected_so.vue";
// import moment from 'moment'

export default {
  components: {
    // detailSelectedBudget
  },
  props: [
    "progress",
    "progressWarehouse",
    "progressRestriction",
    "isCancel",
    "territory",
    "salesChannel",
    "customerCategory",
    "isClosed",
    "isBilled",
  ],
  mounted() {
    this.selectedBudget = this.dataBudget;
    this.$store.dispatch("user/getPermissions","promotion-budget");
  },
  data() {
    return {
      selectedBudget: {},
      dataBudget: [
        {
          BudgetLine: [{}],
          Customer: {
            Code: "",
            Name: "",
            Address: "",
            Phone: "",
            CustomerCategory: {
              Name: "",
            },
          },
          Company: {
            Code: "",
            Name: "",
          },
          SalesChannel: {
            Code: "",
            Name: "",
          },
          BillAddress: {
            Address: "",
            City: "",
            Country: "",
          },
          ShipAddress: {
            Address: "",
            City: "",
            Country: "",
          },
          Salesman: {
            Name: "",
          },
          PaymentTerm: {
            Name: "",
          },
        },
      ],
      TAB_PENDING: 1,
      TAB_APPROVED: 2,
      TAB_INACTIVE: 3,
      TAB_AMMEND:4,
      TAB_EXPIRED:5,
      TAB_REJECTED: 6,
      TAB_CANCELED: 7,
      TAB_NEW: 0,
      STS_EXPIRED: -3,
      STS_AMMEND: -2,
      STS_INACTIVE: -1,
      STS_NEW: 0,
      STS_PENDING: 1,
      STS_APPROVED: 2,
      STS_REJECTED: 3,
      STS_CANCELED: 4,
      budgetCode: "",

      limits: [10, 25, 50, 100, "All"],
      limitShow: 10,
      drawData: 0,
      isActive: 1,
      search: "",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      recordsTotal: 0,
      maxPage: 2,
      dataEnd: 0,
    };
  },
  watch: {
    watchedProperties: function () {
      this.getData(1, this.getStatus());
    },
  },
  computed: {
    watchedProperties() {
      return `${this.territory}||${this.salesChannel}||${this.customerCategory}||${this.search}`;
    },
    currentPage: {
      get() {
        return 1;
      },
      set(page) {
        this.page(page);
      },
    },
  },
  methods: {
    dateFormat(date) {
      if (date) {
        // return moment(String(date)).utcOffset(0).format('DD/MM/YYYY hh:mm')
        let dt = new Date(date);
        let d = dt.getDate();
        let m = dt.getMonth() + 1;
        let y = dt.getFullYear();
        return d + "/" + m + "/" + y;
      }
    },

    formatCurrency(nominal) {
      if (nominal && nominal > 0) {
        // nominal > 0 untuk handle return NaN
        nominal = (nominal / 1).toFixed(2).replace(".", ",");
        return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return 0;
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    getBudget() {
      const search = this.budgetCode;
      if (search) {
        this.selectedBudget = this.dataBudget.filter(
          (so) => so.Code.indexOf(search) > -1
        )[0]; // filter + get 1st record
      }
    },

    clickEdit(budget, type) {
      this.$emit("clickEdit", budget, this.getTab(), type);
    },
    clickView(budget) {
      this.$emit("clickView", budget, this.getTab());
    },

    clickDelete(budget) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "You are about to cancel '" + budget.Code + "' ?",
        accept: this.actionDelete,
        parameters: budget,
      });
    },

    actionDelete(budget) {
      this.$vs.loading();
      this.$http
        .post("api/v1/promotion-budget/change-status", {
          status: this.STS_CANCELED,
          code: budget.Code,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Promotion Budget successfully cancelled",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
            this.getData(1, this.getStatus());
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
            this.getData(1, this.getStatus());
          }
        });
    },

    clickBudgetCode(budget) {
      this.closeDetail();
      setTimeout(function () {}, 3000);
      this.budgetCode = budget.Code;
      this.getBudget();
      this.detail = true;
      this.scrollToTop();
    },

    closeDetail() {
      this.detail = false;
      this.$emit("selectBudget", null);
    },

    page(page) {
      this.getData(page, this.getStatus());
      return page;
    },

    changeLimit(limit) {
      this.limitShow = limit;
      this.getData(this.isActive, this.getStatus());
    },

    getStatus() {
      var status = 0;
      switch (this.progress) {
        case "pending":
          status = this.STS_PENDING;
          break;
        case "approved":
          status = this.STS_APPROVED;
          break;
        case "in-active":
          status = this.STS_INACTIVE;
          break;
        case "rejected":
          status = this.STS_REJECTED;
          break;
        case "canceled":
          status = this.STS_CANCELED;
          break;
        case "ammend":
          status = this.STS_AMMEND;
          break;
        case "expired":
          status = this.STS_EXPIRED;
          break;
        default:
          status = this.STS_NEW;
          break;
      }
      return status;
    },

    getData(page = 1, status = 0) {
      this.isActive = page;
      this.$vs.loading();

      var parameter = {
        territory_code: this.territory,
        sales_channel_code: this.salesChannel,
        customer_category_id: this.customerCategory,
        progress_status: this.progress,
        progress_restriction: this.progressRestriction,
        creator_restriction: true,
        is_cancel: this.isCancel,
        is_closed: this.isClosed,
        is_billed: this.isBilled,
        search: this.search,
        status: status,
        limit: this.limitShow,
        offset: this.limitShow * (page - 1),
      };

      if (this.progressRestriction == false) {
        parameter.approval_status = 0;
        parameter.approval_manager = 0;
        parameter.approval_release = 0;
        parameter.validation_status = 0;
        parameter.allocation_status = 0;
      }

      this.$http
        .get("/api/v1/promotion-budget", {
          params: parameter,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.drawData = this.drawData + 1;
            this.dataBudget = resp.data.Budgets;
            this.recordsTotal = resp.data.RecordsTotal;
            this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
            this.dataEnd = resp.data.ResultsTotal;
            this.checkedAll = false;
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
    clickSendApproval(budget) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "You will apply for approval '" + budget.Code + "' ?",
        accept: this.actionApproval,
        parameters: budget,
      });
    },
    actionApproval(budget) {
      this.$vs.loading();
      this.$http
        .post("api/v1/promotion-budget/change-status", {
          status: this.STS_PENDING,
          code: budget.Code,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Promotion Budget successfully submitted for approval",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
            this.getData(1, this.getStatus());
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
            this.getData(1, this.getStatus());
          }
        });
    },
    clickInActive(budget) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "You will disable this data '" + budget.Code + "' ?",
        accept: this.actionInActive,
        parameters: budget,
      });
    },
    actionInActive(budget) {
      this.$vs.loading();
      this.$http
        .post("api/v1/promotion-budget/in-active", {
          code: budget.Code,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data deactivated successfully",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
            this.getData(1, this.getStatus());
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
            this.getData(1, this.getStatus());
          }
        });
    },
    getTab() {
      var status = 0;
      switch (this.progress) {
        case "pending":
          status = this.TAB_PENDING;
          break;
        case "approved":
          status = this.TAB_APPROVED;
          break;
        case "in-active":
          status = this.TAB_INACTIVE;
          break;
        case "rejected":
          status = this.TAB_REJECTED;
          break;
        case "canceled":
          status = this.TAB_CANCELED;
          break;
        default:
          status = this.TAB_NEW;
          break;
      }
      return status;
    },
  },
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
