<style scoped>
.text-danger {
  color: red !important;
}
</style>
<style>
.multiselect-inside-table > .multiselect__content-wrapper {
  position: static;
}
</style>
<template>
  <!-- HORIZONTAL LAYOUT -->
  <div class="vx-row">
    <div class="vx-col sm:w-1/2 w-full mb-base">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Budget Code</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input class="w-full" v-model="budget.Code" disabled />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Description</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-textarea
            maxlength="255"
            class="w-full"
            :label="charactersLeft"
            name="Description"
            v-model="budget.Description"
            :disabled="disabledFrom"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <multiselect
            v-model="selectedTerritory"
            :options="optionTerritory"
            placeholder="Type to search"
            track-by="name"
            label="name"
            :max-height="125"
            :allow-empty="true"
            :multiple="true"
            :disabled="disabledFrom"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} {{ props.option.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} {{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Valid from</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <datepicker
            :inline="false"
            v-model="budget.ValidFrom"
            placeholder="Select valid from"
            :disabled="disabledFrom"
          ></datepicker>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Valid to</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <datepicker
            :inline="false"
            v-model="budget.ValidTo"
            placeholder="Select valid to"
            :disabled="disabledFrom"
          ></datepicker>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Created by</span>
        </div>
        <div class="vx-col sm:w-2/6">
          <vs-input class="w-full" v-model="budget.CreatedByName" :disabled="true" />
        </div>
        <div class="vx-col sm:w-1/6 w-full text-right">
          <span>Created Date</span>
        </div>
        <div class="vx-col sm:w-2/6">
          <datepicker
            :inline="false"
            v-model="budget.CreatedByDate"
            placeholder="Select valid to"
            :disabled="true"
          ></datepicker>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6">
          <span>Approved by</span>
        </div>
        <div class="vx-col sm:w-2/6">
          <vs-input class="w-full" v-model="budget.ApprovalByName" />
        </div>
        <div class="vx-col sm:w-1/6 text-right">
          <span>Approved Date</span>
        </div>
        <div class="vx-col sm:w-2/6">
          <datepicker
            :inline="false"
            v-model="budget.ApprovalByDate"
            placeholder="Select valid to"
            :disabled="disabledFrom"
          ></datepicker>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Type</span>
        </div>
        <div class="vx-col vx-row sm:w-5/6 w-full">
          <div class="vx-col sm:w-1/6">
            <input
              type="radio"
              value="1"
              v-model="budget.Type"
              :disabled="disabledFrom"
            />
            <label> Quantity</label>
          </div>
          <div class="vx-col sm:w-1/6">
            <input
              type="radio"
              value="2"
              v-model="budget.Type"
              :disabled="disabledFrom"
            />
            <label> Value</label>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Note</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-textarea
            maxlength="255"
            class="w-full"
            :label="charactersLeft"
            name="Note"
            v-model="budget.Note"
            :disabled="disabledFrom"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Attachment</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <input
            id="fileInput"
            name="file"
            class="w-full inputx"
            type="file"
            ref="file"
            multiple="multiple"
            accept=".jpg, .jpeg, .png .pdf"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Source</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <multiselect
            v-model="selectedSource"
            :options="optionSource"
            placeholder="Type to search"
            track-by="id"
            label="name"
            :max-height="125"
            :allow-empty="false"
            :multiple="false"
            :disabled="disabledFrom"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>External Code</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input
            class="w-full"
            v-model="budget.ExternalCode"
            :disabled="disabledFrom"
          />
        </div>
      </div>
      <div class="vx-row mb-6" v-show="isInputSup">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Supplier Code</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <multiselect
            v-model="selectSupplier"
            :options="optionSupplier"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="130"
            :limit="3"
            placeholder=" Type to search"
            track-by="id"
            label="code"
            :disabled="disabledFrom"
          >
            <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title">{{ dt.option.code }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="dt">
              <div class="option__desc">
                <span class="option__title">{{
                  dt.option.code + " " + dt.option.name
                }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Currency</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <multiselect
            v-model="selectedCurrency"
            :options="optionCurrencyCode"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="120"
            :limit="3"
            placeholder=" Type to search"
            track-by="Code"
            label="Code"
            :disabled="disabledFrom"
          >
            <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title">{{ dt.option.Code }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="dt">
              <div class="option__desc">
                <span class="option__title">{{ dt.option.Code }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Budget Value</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vue-number-input
            :min="0"
            :step="1"
            class="w-full"
            @keypress="isNumber($event)"
            v-model="budget.Value"
            @change="onInputNumberValue"
            :disabled="disabledFrom"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Percentage Reference</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vue-number-input
            :min="0"
            :max="100"
            :step="1"
            class="w-full"
            type="number"
            @keypress="isNumber($event)"
            @change="onInputNumberRef"
            v-model="budget.PercentageReference"
            :disabled="!isInputPercentRef || disabledFrom"
          />
        </div>
      </div>
      <br />
    </div>
    <div class="vx-col sm:w-1/2 w-full mb-base"></div>
    <hr />
    <br />
    <div class="vx-col w-full mb-base">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-table :data="budget.BudgetLine" stripe style="width: 100%">
            <template slot="thead">
              <vs-th>No</vs-th>
              <vs-th style="width: 30%">Owner</vs-th>
              <vs-th>Percentage (%)</vs-th>
              <vs-th>Value Distribution</vs-th>
              <vs-th v-if="!disabledFrom&&this.$store.getters['user/hasPermissions']((budget.Code&&budget.Code!=''?'create':'edit'))">Action</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="index" v-for="(dt, index) in data">
                <vs-td style="align-item-right">{{ index + 1 }}</vs-td>
                <vs-td style="align-item-center">
                  <multiselect
                    class="multiselect-inside-table"
                    v-model="dt.SelectedSource"
                    :options="optionBudgetSourceFiltered"
                    placeholder="Type to search"
                    track-by="Name"
                    label="Name"
                    :max-height="120"
                    :allow-empty="true"
                    :multiple="false"
                    :disabled="disabledFrom||selectedSource.id == 1"
                  >
                    <span slot="noResult">Oops! No data found</span>
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title"
                          >{{ props.option.Code }} {{ props.option.Name }}</span
                        >
                      </span>
                    </template>

                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title"
                          >{{ props.option.Code }} {{ props.option.Name }}</span
                        >
                      </div>
                    </template>
                  </multiselect>
                </vs-td>
                <vs-td>
                  <vue-number-input
                    inline
                    controls
                    :min="0"
                    :step="1"
                    size="small"
                    v-model="dt.Percentage"
                    :value="0"
                    @keypress="isNumber($event)"
                    @change="updateValueDistribution(index)"
                    @input="onInputLinePercent(index)"
                    :disabled="
                      budget.PercentageReference <= 0 || budget.Value <= 0 || disabledFrom
                    "
                  />
                </vs-td>
                <vs-td>
                  <vue-number-input
                    inline
                    controls
                    :min="0"
                    :step="1"
                    size="small"
                    v-model="dt.Value"
                    :value="0"
                    @input="onInputLineValue(index)"
                    @keypress="isNumber($event)"
                    :disabled="budget.Value <= 0 || disabledFrom"
                  />
                </vs-td>
                <vs-td v-if="!disabledFrom&&this.$store.getters['user/hasPermissions']((budget.Code&&budget.Code!=''?'create':'edit'))">
                  <feather-icon
                    v-if="index == 0"
                    title="Add"
                    icon="PlusIcon"
                    svgClasses="w-5 h-5 text-success stroke-current"
                    class="ml-2"
                    @click="addLine()"
                  />
                  <feather-icon
                    v-else
                    title="Remove"
                    icon="MinusIcon"
                    svgClasses="w-5 h-5 text-danger stroke-current"
                    class="ml-2"
                    @click="removeLine(index)"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col sm:w-6/12 w-full">
          <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()" 
          v-if="!disabledFrom&&this.$store.getters['user/hasPermissions'](budget.Code&&budget.Code!=''?'create':'edit')"
            >Submit</vs-button
          >
          <!-- <vs-button v-if="update" color="warning" class="mr-3 mb-2" @click="ResetForm()">Reset</vs-button> -->
          <vs-button v-if="update" color="danger" class="mr-3 mb-2" @click="backTo()"
            >Cancel</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import flatPickr from "vue-flatpickr-component";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import "flatpickr/dist/flatpickr.css";
import fieldPromotionCode from "../component/field_promotion_code.vue";
import datatablePromotionCode from "../component/datatable_promotion_code.vue";
// import fieldReservationCode from '../component/field_reservation_code.vue'
// import datatableReservationCode from "../component/datatable_reservation_code.vue";
// import moment from 'moment'

export default {
  props: ["territoryCode", "territoryId", "selectedBudget", "disabledFrom"],
  components: {
    Datepicker,
    VueNumberInput,
    flatPickr,
    fieldPromotionCode,
    datatablePromotionCode,
    // fieldReservationCode,
    // datatableReservationCode
  },
  mounted() {
    if (this.selectedBudget && this.selectedBudget.Code) {
      this.resetBudget = Object.assign({}, this.selectedBudget);
      this.budget = this.selectedBudget;

      this.budget.ValidFrom = new Date(this.budget.ValidFrom);
      this.budget.ValidTo = new Date(this.budget.ValidTo);

      this.update = true;
      this.selectedCurrency = {
        ID: 0,
        Code: this.budget.CurrencyCode,
      };

      this.selectedSource = this.optionSource.find(
        (source) => source.id === this.budget.Source
      );
    } else {
      this.setDefaultDate();
      this.budget.CreatedByName = this.$userLogin.name;
      this.resetBudget = Object.assign({}, this.budget);
    }

    this.getOptionTerritory();
    this.getOptionCurrencyCode();
    this.getOptionBudgetSource();
    this.getOptionSupplier();
    this.$store.dispatch("user/getPermissions","promotion-budget");
  },
  computed: {
    DueDate: {
      get() {
        var d = new Date();
        if (this.selectedBudget && this.selectedBudget.DueDate) {
          d = new Date(this.selectedBudget.DueDate);
          d.setHours(d.getHours() - 7);
        } else {
          d = new Date(this.budget.DueDate);
        }
        return d;
      },
      set(val) {
        this.DueDatePost = val;
        return val;
      },
    },
    charactersLeft() {
      var char = this.budget.Description.length,
        limit = 255;

      return "counter " + (limit - char);
    },
    currentPage: {
      get() {
        return 1;
      },
      set(page) {
        return page;
      },
    },
  },
  watch: {
    selectedSource: function (newSource, oldSource) {
      if (oldSource.id == 0 && this.budget.Code != "") {
        return true;
      }
      this.filterOptionBudgetSource();
    },
  },
  data: () => ({
    configFlatPickrDueDate: {
      // wrap: true, // set wrap to true only when using 'input-group'
      altFormat: "F j, Y",
      // altInput: true,
      dateFormat: "j F Y H:i",
      enableTime: true,
      minTime: "00:00",
      plugins: [new ConfirmDatePlugin()],
    },

    configFlatPickrDate: {
      // wrap: true, // set wrap to true only when using 'input-group'
      altFormat: "F j, Y",
      // altInput: true,
      dateFormat: "j F Y",
      enableTime: false,
    },
    // createdBy: {
    //   username: "",
    //   date: new Date(),
    // },
    // approvedBy: {
    //   username: "",
    //   date: new Date(),
    // },
    hour: "",
    minute: "",

    update: false,

    selectedTerritory: [],
    selectedCurrency: {},
    selectedSource: { id: 0, name: "" },

    resetBudget: {},
    budget: {
      Code: "",
      ExternalCode: "",
      Description: "",
      Note: "",
      Type: 1,
      Source: 1,
      ValidFrom: "",
      ValidTo: "",
      CurrencyCode: "",
      Value: 0,
      ApprovalByName: "",
      ApprovalByDate: new Date(),
      CreatedByName: "",
      CreatedByDate: new Date(),
      TypeEdit: "",
      BudgetLine: [
        {
          ID: 0,
          BudgetID: null,
          Type: null,
          // Account: null,
          Percentage: null,
          Value: null,
          SupplierID: null,
          CostCenterID: null,
          SelectedSource: null,
        },
      ],
      BudgetTerritory: [
        {
          ID: 0,
          BudgetID: null,
          TerritoryID: null,
        },
      ],
      PercentageReference: 0,
    },

    optionTerritory: [{}],
    optionSupplier: [],
    selectSupplier: {
      id: 0,
      name: "",
      code: "",
    },
    optionCurrencyCode: [{}],
    optionBudgetSource: [{}],
    optionBudgetSourceFiltered: [{}],
    optionSource: [
      { id: 1, name: "Internal" },
      { id: 2, name: "Supplier" },
      { id: 3, name: "Mix" },
    ],

    limits: [10],
    limitShow: 10,
    drawData: 0,
    isActive: 1,
    search: "",
    isInputSup: false,
    isInputPercentRef: false,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,
  }),
  methods: {
    checkDate() {
      let validFrom = this.budget.ValidFrom;
      let validTo = this.budget.ValidTo;

      if (validFrom.getFullYear() > validTo.getFullYear()) {
        console.log(validFrom.getFullYear(), validTo.getFullYear());
        return false;
      }

      if (validFrom.getMonth() > validTo.getMonth()) {
        console.log(validFrom.getMonth(), validTo.getMonth());
        return false;
      }

      if (
        validFrom.getMonth() == validTo.getMonth() &&
        validFrom.getDate() > validTo.getDate()
      ) {
        console.log(validFrom.getDate(), validTo.getDate());
        return false;
      }

      return true;
    },
    checkPercentage() {
      let totalPerc = 0;
      this.budget.BudgetLine.forEach((el) => {
        totalPerc += parseFloat(el.Percentage);
      });
      if (
        parseFloat(totalPerc) != parseFloat(this.budget.PercentageReference) &&
        this.isInputPercentRef
      ) {
        return false;
      }
      return true;
    },
    checkValue() {
      let totalValue = 0;
      this.budget.BudgetLine.forEach((el) => {
        totalValue += parseFloat(el.Value);
      });
      if (parseFloat(totalValue) > parseFloat(this.budget.Value)) {
        return false;
      }
      return true;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    formatCurrency(nominal) {
      if (nominal && nominal > 0) {
        // nominal > 0 untuk handle return NaN
        nominal = (nominal / 1).toFixed(2).replace(".", ",");
        return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return 0;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    ResetForm() {
      this.budget = Object.assign({}, this.resetBudget);
    },
    backTo() {
      this.CancelForm();
      this.$emit("backTo");
    },
    CancelForm() {
      this.update = false;
      this.selectedTerritory = [];
      this.$refs.file.value = null;
      this.resetBudget = {
        Code: "",
        Description: "",
        Type: 1,
        ValidFrom: "",
        ValidTo: "",
        CurrencyCode: "",
        Value: 0,
        PercentageReference: 0,
        BudgetLine: [
          {
            BudgetID: null,
            Type: null,
            // Account: null,
            Percentage: null,
            Value: null,
            SupplierID: null,
            CostCenterID: null,
            SelectedSource: null,
          },
        ],
      };

      this.budget = Object.assign({}, this.resetBudget);
      this.selectedCurrency = {};

      this.$emit("selectBudget", {});
    },
    changeLimit(limit) {
      this.limitShow = limit;
    },
    changePrice() {
      this.calculated = false;
    },
    changeCharge() {
      this.calculated = false;
    },
    addLine() {
      this.budget.BudgetLine.push({
        BudgetID: null,
        Type: null,
        // Account: null,
        Percentage: null,
        Value: null,
        SupplierID: null,
        CostCenterID: null,
        SelectedSource: null,
      });
    },
    removeLine(index) {
      this.budget.BudgetLine.splice(index, 1);
    },
    getOptionTerritory() {
      this.$http.get("/api/v1/master/territory").then((resp) => {
        if (resp.code == 200) {
          this.optionTerritory = resp.data.records;
          if (this.budget.Code != "") {
            this.budget.BudgetTerritory.forEach((dt, _) => {
              let temp = this.optionTerritory.find(
                (territory) => territory.id === dt.TerritoryID
              );
              this.selectedTerritory.push(temp);
            });
          }
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Territory option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
      });
    },
    getOptionCurrencyCode() {
      this.$vs.loading();

      this.$http
        .get("/api/v1/master/currency", {
          params: {
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionCurrencyCode = resp.data.records;
            if (this.budget.CurrencyCode == 0 || this.budget.CurrencyCode == null) {
              this.selectedCurrency = {
                ID: this.optionCurrencyCode[0].ID,
                Code: this.optionCurrencyCode[0].Code,
              };
            } else {
              for (let index = 0; index < this.optionCurrencyCode.length; index++) {
                if (this.optionCurrencyCode[index].code == this.budget.CurrencyCode) {
                  this.selectedCurrency = {
                    ID: this.optionCurrencyCode[index].ID,
                    Code: this.optionCurrencyCode[index].Code,
                  };
                  break;
                }
              }
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Currency option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionBudgetSource() {
      this.$http.get("/api/v1/promotion-budget/source").then((resp) => {
        if (resp.code == 200) {
          this.optionBudgetSource = resp.data.BudgetSources;
          this.budget.BudgetLine.forEach((dt, _) => {
            let temp = null;
            if (dt.SupplierID != 0) {
              temp = this.optionBudgetSource.find(
                (lineSource) =>
                  lineSource.Model === "suppliers" && lineSource.ID === dt.SupplierID
              );
            } else if (dt.CostCenterID != 0) {
              temp = this.optionBudgetSource.find(
                (lineSource) =>
                  lineSource.Model === "cost_centers" && lineSource.ID === dt.CostCenterID
              );
            }
            if (temp != null) {
              dt.SelectedSource = temp;
            }
          });
          this.filterOptionBudgetSource();
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Budget SOurce option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
      });
    },
    filterOptionBudgetSource() {
      // 1: internal; 2: supplier; 3: mix;
      if (this.selectedSource.id == 1) {
        this.optionBudgetSourceFiltered = this.optionBudgetSource.filter(
          (dt) => dt.Model.indexOf("cost_centers") > -1
        );
        this.isInputSup = false;
        this.isInputPercentRef = false;
        this.budget.PercentageReference = 100;
      } else if (this.selectedSource.id == 2) {
        this.optionBudgetSourceFiltered = this.optionBudgetSource.filter(
          (dt) => dt.Model.indexOf("suppliers") > -1
        );
        this.isInputSup = true;
        this.isInputPercentRef = true;
        this.budget.PercentageReference = 0;
        this.budget.BudgetLine.forEach((el, i) => {
          this.budget.BudgetLine[i].Percentage = 0;
        });
      } else {
        this.optionBudgetSourceFiltered = this.optionBudgetSource;
        this.isInputSup = true;
        this.isInputPercentRef = true;
      }

      this.budget.BudgetLine.forEach((dt, _) => {
        if ( this.selectedSource.id == 1 ) {
          dt.SelectedSource = null;
        } else if (
          this.selectedSource.id == 2 &&
          dt.SelectedSource != null &&
          dt.SelectedSource.Model != "suppliers"
        ) {
          dt.SelectedSource = null;
        }
      });
    },
    setParam() {
      var territoryParams = [];
      this.selectedTerritory.forEach((el) => {
        territoryParams.push({
          ID: el.ID,
          BudgetID: el.BudgetID,
          TerritoryID: el.id,
        });
      });
      let param = {
        code: this.budget.Code,
        currency_code: this.selectedCurrency.Code,
        description: this.budget.Description,
        note: this.budget.Note,
        valid_from: this.budget.ValidFrom,
        valid_to: this.budget.ValidTo,
        type: parseInt(this.budget.Type),
        source: parseInt(this.selectedSource.id),
        value: parseFloat(this.budget.Value),
        percentage_reference: parseFloat(this.budget.PercentageReference),
        budget_line: this.budget.BudgetLine,
        created_date: this.budget.CreatedByDate,
        approval_name: this.budget.ApprovalByName,
        approval_date: this.budget.ApprovalByDate,
        supplier_id: this.selectSupplier.id,
        type_edit: this.budget.TypeEdit,
        external_code: this.budget.ExternalCode ? this.budget.ExternalCode : "",
        // budget_territory: this.budget.BudgetTerritory,
        budget_territory: territoryParams,
      };

      this.budget.BudgetLine.forEach((dt, _) => {
        if (dt.SelectedSource!=null) {
          if (dt.SelectedSource.Model == "cost_centers") {
            dt.CostCenterID = dt.SelectedSource.ID;
          } else if (dt.SelectedSource.Model == "suppliers") {
            dt.SupplierID = dt.SelectedSource.ID;
          }
        }
      });

      this.selectedTerritory.forEach((dt, _) => {
        dt.territory_id = dt.id;
      });
      console.log("param : ", param);
      return param;
    },

    SubmitForm() {
      var file = this.$refs.file.files[0];
      console.log(this.$refs.file.files);
      var formData = new FormData();
      // var bo = true;
      // if (bo) {
      if (file != null) {
        var dateNow = new Date();
        var now =
          dateNow.getFullYear() + "" + (dateNow.getMonth() + 1) + "" + dateNow.getDate();
        now +=
          +"" +
          dateNow.getHours() +
          "" +
          dateNow.getMinutes() +
          "" +
          dateNow.getSeconds();
        formData.append("file", file);
        formData.append("user_agent", navigator.userAgent);
        formData.append("file_name", now + "_" + file.name);
        formData.append("url", "/api/v1/promotion-budget/import-attachment");
      }
      if (
        this.budget.ValidFrom == "" ||
        this.budget.ValidFrom == 0 ||
        this.budget.ValidFrom == null
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Please select valid from first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      if (
        this.budget.ValidTo == "" ||
        this.budget.ValidTo == 0 ||
        this.budget.ValidTo == null
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Please select valid to first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      if (!this.checkDate()) {
        this.$vs.notify({
          title: "Error",
          text: "Please select valid to Date must be equal or after valid from",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      if (!this.checkPercentage()) {
        this.$vs.notify({
          title: "Error",
          text: "Total percentage must be the same as the Percentage Reference",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      if (!this.checkValue()) {
        this.$vs.notify({
          title: "Error",
          text: "Total distribution value cannot be more than the budget value",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      if (
        this.selectedCurrency.Code == "" ||
        this.selectedCurrency.Code == 0 ||
        this.selectedCurrency.Code == null
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Currency",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      formData.append("code", this.setParam().code);
      formData.append("currency_code", this.setParam().currency_code);
      formData.append("description", this.setParam().description);
      formData.append("note", this.setParam().note);
      formData.append("valid_from", this.setParam().valid_from);
      formData.append("valid_to", this.setParam().valid_to);
      formData.append("type", this.setParam().type);
      formData.append("source", this.setParam().source);
      formData.append("value", this.setParam().value);
      formData.append("percentage_reference", this.setParam().percentage_reference);
      formData.append("budget_line", JSON.stringify(this.setParam().budget_line));
      formData.append("supplier_id", this.setParam().supplier_id);
      formData.append("created_date", this.setParam().created_date);
      formData.append("approval_name", this.setParam().approval_name);
      formData.append("approval_date", this.setParam().approval_date);
      formData.append("type_edit", this.setParam().type_edit);
      formData.append("external_code", this.setParam().external_code);
      formData.append(
        "budget_territory",
        JSON.stringify(this.setParam().budget_territory)
      );
      this.setParam().file = file;
      this.$vs.loading();
      this.$http
        .post("/api/v1/promotion-budget/store", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Promotion Budget has been saved",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.CancelForm();
            this.setDefaultDate();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    setDefaultDate() {
      var day = new Date();
      var nextDay = new Date(day);
      nextDay.setDate(day.getDate() + 1);

      this.budget.Date = day;
      this.budget.DueDate = nextDay;
    },
    getTime(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/customer/work-time/" + id).then((resp) => {
        if (resp.code == 200) {
          var day = new Date();
          var nextDay = new Date(day);
          nextDay.setDate(day.getDate() + 1);
          nextDay.setHours(resp.data[0]);
          nextDay.setMinutes(resp.data[1]);
          this.budget.DueDate = nextDay;
        }
        this.$vs.loading.close();
      });
    },
    updateValueDistribution(index) {
      // HASIL DISKUSI DENGAN PAK SINGGIH 14-08-2023
      // - perhitungan value distribution = (percentage / percentage ref) * Budget Value
      this.budget.BudgetLine[index].Value =
        (this.budget.BudgetLine[index].Percentage / this.budget.PercentageReference) *
        parseFloat(this.budget.Value);
    },
    onInputLinePercent(index) {
      if (this.budget.BudgetLine[index].Percentage) {
        this.budget.BudgetLine[index].Percentage *= 1;
      }
    },
    onInputLineValue(index) {
      if (this.budget.BudgetLine[index].Value) {
        this.budget.BudgetLine[index].Value *= 1;
      }
    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            resp.data.records.forEach((el) => {
              this.optionSupplier.push({
                id: el.ID,
                code: el.Code,
                name: el.Name,
              });
              let temp = this.optionSupplier.find(
                (supp) => supp.id == this.budget.SupplierID
              );
              if (temp) {
                this.selectSupplier = temp;
              }
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    onInputNumberValue() {
      if (this.budget.Value) {
        this.budget.Value *= 1;
      }
    },
    onInputNumberRef() {
      if (this.budget.PercentageReference) {
        this.budget.PercentageReference *= 1;
      }
    },
  },
};
</script>
