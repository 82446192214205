<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="
              p-3
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <!-- <vs-th></vs-th> -->
        <vs-th
          v-for="(head, index) in this.table.heads"
          :key="index"
          :sort-key="head.field"
          >{{ head.title }}</vs-th
        >
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ tr.invoice_code }}</vs-td>
          <vs-td>{{ dateFormatWithTime(tr.invoice_date) }}</vs-td>
          <vs-td class="text-right">{{ numberFormat(tr.total_qty) }}</vs-td>
          <vs-td class="text-right">{{ numberFormat(tr.total_amount) }}</vs-td>
          <vs-td>
            <p>{{ tr.so_code }}</p>
            <p>SO ND6 : {{ tr.so_nd_six }}</p>
          </vs-td>
          <vs-td>{{ dateFormatWithTime(tr.so_date) }}</vs-td>
          <vs-td class="text-right">{{ numberFormat(tr.so_total_qty) }}</vs-td>
          <vs-td class="text-right">{{ numberFormat(tr.so_total_amount) }}</vs-td>
          <vs-td><vs-button @click="generateInv(tr.so_id, tr.invoice_id)">Export</vs-button></vs-td>
          <!-- <vs-td class="text-primary" v-if="tr.exp_id != 0 && tr.exp_status == 'Pending'">Processing</vs-td> -->
          <!-- <vs-td v-if="tr.exp_id != 0 && tr.exp_status == 'Exported'"><vs-button @click="generateInv(tr.so_id)">ReGenerate</vs-button></vs-td> -->
          <!-- <vs-td v-if="tr.exp_id != 0 && tr.exp_status == 'Exported'"><vs-button @click="downloadInv(tr.invoice_id, tr.file_path, tr.file_name)">{{ (!tr.download_by) ? 'Generate' : 'ReGenerate' }}</vs-button></vs-td> -->
          <vs-td v-if="!tr.download_by">Not Yet</vs-td>
          <vs-td v-else>Exported</vs-td>
          <vs-td style="white-space: pre-line;">{{ formatDownloadBy(tr.download_by) }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
import { numberFormat } from "@/utils/helper";
export default {
  props: {
    territoryIDs: {
      type: String,
    },
    status: {
      type: String,
    },
    invDateFrom: {
      type: Date,
    },
    invDateTo: {
      type: Date,
    },
    soNumberNd6: {
      type: String,
    },
    invNumber: {
      type: String,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState()
    };
  },
  methods: {
    numberFormat,
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        heads: [
          {
            field: "invoice_code",
            title: "Invoice Number"
          },
          {
            field: "invoice_date",
            title: "Invoice Date"
          },
          {
            field: "total_qty",
            title: "Total Qty Invoice (PCS)"
          },
          {
            field: "total_amount",
            title: "Total Amount Invoice"
          },
          {
            field: "so_code",
            title: "Sales Order Number"
          },
          {
            field: "so_date",
            title: "Sales Order Date"
          },
          {
            field: "so_total_qty",
            title: "Qty SO (PCS)"
          },
          {
            field: "so_total_amount",
            title: "Amount SO"
          },
          {
            field: "invoice_id",
            title: "Export"
          },
          {
            field: "so_id",
            title: "Export Status"
          },
          {
            field: "download_by",
            title: "Exported by"
          }
        ]
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/billing-cmi", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territoryIDs: this.territoryIDs,
            status: this.status,
            invDateFrom: this.invDateFrom ? moment(this.invDateFrom).format("YYYY-MM-DD") : null,
            invDateTo: this.invDateTo ? moment(this.invDateTo).format("YYYY-MM-DD") : null,
            soNumberNd6: this.soNumberNd6,
            invNumber: this.invNumber,
          }
        })
        .then(resp => {
          this.$vs.loading.close();
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
          }
        });
    },
    generateInv(soID, invID) {
      this.$vs.loading();
      this.$http.get(`/api/v1/billing-cmi/generate/${soID}/${invID}`)
      .then(resp => {
        this.$vs.loading.close();
        if (resp.code == 200) {
          this.$vs.notify({
            color: "primary",
            title: "Success",
            text: "Please wait background process is running...",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check-circle",
            time: 3000,
          });
          this.getData();
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
            time: 10000,
          });
        }          
      })        
    },
    downloadInv(invId, filePath, fileName) {
      try {
        this.$http.get(`/api/v1/billing-cmi/download/${invId}`)
        .then(resp => {
          if (resp.code == 200) {
            this.downloadFileAwsS3(filePath)
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "File downloaded successfully:", fileName,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Failed",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
              time: 10000,
            });
          }
        })
      } catch (error) {
        console.error("Error downloading file:", error.message);
        this.$vs.notify({
          color: "danger",
          title: "Failed",
          text: error.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
          time: 10000,
        });
      }
    },
    formatDownloadBy(value) {
      if (!value || typeof value !== 'string') {
        return ""; // Return an empty string or handle null/invalid input as needed
      }
      return value
        .split(";")                      // Split the string into an array
        .filter((name) => name.trim())   // Remove empty values and trim whitespace
        .filter((name, index, self) => self.indexOf(name) === index) // Remove duplicates
        .reverse()                       // Reverse the order
        .join("\n");                     // Join with newline characters
    },
    dateFormatWithTime(date) {
      if (date) {
        if (String(date).includes("-")) {
          return moment.utc(String(date)).format("YYYY-MM-DD");
        }else  if (String(date).includes("/")) {
          const inputDate = String(date);
          const parts = inputDate.split("/");
          if (parts.length === 3) {
            return  moment.utc(String(`${parts[2]}-${parts[1]}-${parts[0]}`)).format("YYYY-MM-DD") ;
          }else {
            return ""
          }
        }else {
          const inputDate = String(date);
          if (inputDate.length >= 8) {
            const day = inputDate.substr(0, 2);
            const month = inputDate.substr(2, 2);
            const year = inputDate.substr(4, 4);

            const formattedDate = `${year}-${month}-${day}`;
            return  moment.utc(String(formattedDate)).format("YYYY-MM-DD") ;

          } else {
            return ""
          }
        }
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      // return moment(val).local();
      return moment(val)
        .add(-7, "h")
        .utc()
        .local();
    }
  },
  watch: {
    draw(val) {
      if (val > 0) {
        this.getData();
      }
    }
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      }
    }
  }
};
</script>
