<template>
  <vx-card :title="data.title">
    <div class="vx-row mb-12">
      <div class="vx-col w-5/6 ml-auto mr-auto">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <multiselect
              v-model="data.name.selected"
              :options="data.name.options"
              placeholder="Type to search"
              :max-height="125"
              v-validate="'required'"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option }}</span>
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button
              class="mr-3 mb-2"
              @click="handleSubmit"
              v-if="this.$store.getters['user/hasPermissions']('create')"
              >Submit</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
export default {
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          title: "Setting User Work Number ID",
          id: null,
          name: {
            options: ["Company", "Territory", "Area", "Operating Unit"],
            selected: "Company",
          },
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          if (this.data.id == 0) {
            this.postData();
          } else {
            this.putData();
          }
        }
      });
    },
    paramData() {
      return {
        name: this.data.name.selected,
      };
    },
    postData() {
      this.$http
        .post("/api/v1/setting/user-work-number", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Saved",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(
          "/api/v1/setting/user-work-number/" + this.data.id,
          this.paramData()
        )
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Saved",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getData() {
      this.$vs.loading();
      this.$http.get("/api/v1/setting/user-work-number", {}).then((resp) => {
        if (resp.status == "success") {
          console.log(resp.data);
          this.data.id = resp.data.ID;
          if (this.data.id != 0) {
            this.data.name.selected = resp.data.Name;
          }
          this.$vs.loading.close();
        } else {
          console.log(resp.data);
        }
      });
    },
  },
  watch: {},
  mounted() {
    this.getData();
    this.$store.dispatch("user/getPermissions", "user-work-number");
  },
  computed: {},
};
</script>
