import { render, staticRenderFns } from "./Open.vue?vue&type=template&id=280720b4&scoped=true&"
import script from "./Open.vue?vue&type=script&lang=js&"
export * from "./Open.vue?vue&type=script&lang=js&"
import style0 from "./Open.vue?vue&type=style&index=0&id=280720b4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "280720b4",
  null
  
)

export default component.exports