<template>
  <vx-card title="Upload LAF Template">
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>File (*.pdf)</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          class="inputx"
          type="file"
          v-if="uploadReady"
          ref="file_attachment"
          name="file_attachment"
          accept=".pdf"
          required
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button class="ml-auto mt-2" v-on:click="handleSubmit"
        >Submit</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>
<script>
export default {
  components: {},
  props: {
    dataSelected: {
      type: [],
    },
  },
  data() {
    return {
      uploadReady: true,
      optionSupplier: [],
      supplier: null,
      status_submit: false,
      detail: false,
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      selected: {},
    };
  },
  methods: {
    async handleSubmit() {
      this.status_submit = true;
      const formData = new FormData();
      if (this.dataSelected) {
        formData.append("action","update");
        formData.append("template_id", this.dataSelected.id);
      }else  {
          formData.append("action","create");
      }


      const files = this.$refs.file_attachment.files;
      if (files.length === 0) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "File  is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.$vs.loading.close();
        return
      }
      for (let i = 0; i < files.length; i++) {
        formData.append('documents', files[i]);
      }

      this.$vs.loading();
      try {
           await this.$http
              .post('/api/v1/ocr/laf/templates/file',formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                  },
              })
              .then((resp) => {
                  if (resp.success ) {

                    if (resp.data.length>0){
                      let template_id = resp.data[0].id
                      var message = "";
                      this.$vs.notify({
                        color: "success",
                        title: "Upload Success",
                        text: message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                      });

                      if (this.dataSelected){
                        this.dataSelected["id"] = template_id
                      }
                    }else{
                      this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Failed upload data",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                      });
                    }
                  } else {
                      this.$vs.notify({
                          color: "danger",
                          title: "Error",
                          text: resp.message,
                          position: "top-right",
                          iconPack: "feather",
                          icon: "icon-x-circle",
                      });
                     this.$vs.loading.close();
                  }
                  this.$vs.loading.close();

                  this.supplier = null;
                  this.files = [];
                  this.status_submit = false;
                  this.$refs.file_attachment.value = '';

                  this.closeDetail();
              })
              .catch((error) => {
                  this.$vs.notify({
                      color: "danger",
                      title: "Error",
                      text: error,
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle",
                  });
                  this.$vs.loading.close();
              });
      } catch (error) {
        console.error('Error uploading files:', error);
        this.$vs.loading.close();
      }
    },
    closeDetail() {
      this.detail = false;
      if (this.dataSelected){
        this.$emit("close-edit", this.dataSelected);
        return
      }
      this.$emit("close")
    },

  },
  mounted() {
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {

  },
};
</script>
<style scoped>
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.highlight > td {
  background-color: #d0cdf0;
}


.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
</style>
