<template>
    <vx-card>
        <div class="vx-row">
            <div class="vx-col md:w-1/1 w-full">
                <vs-tabs>
                    <vs-tab label="ZDRSALES - Sales Report">    
                        <div class="vx-row mb-6" style="width:50%">
                            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left" @click="$router.go(-1)">Back</vs-button>
                        </div>
                        <div class="vx-row mb-6" style="width:50%">
                            <div class="vx-col sm:w-1/3 w-full flex items-center">
                                <span>Operating Unit / Territory</span>&nbsp;
                                <feather-icon title="Select All" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetTerritoryFilter()"/>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <multiselect
                                    class="selectExample"
                                    v-model="operatingUnit"
                                    :options="optionOU"
                                    :multiple="true"
                                    :allow-empty="false"
                                    :group-select="false"
                                    :max-height="120"
                                    :limit="3"
                                    placeholder="Type to search"
                                    track-by="ID"
                                    label="Name"
                                >
                                    <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title">{{ props.option.Name }}</span>
                                        </span>
                                    </template>
                                        
                                    <template slot="option" slot-scope="props">
                                        <div class="option__desc">
                                            <span class="option__title">{{ props.option.SelectName }}</span>
                                        </div>
                                    </template>

                                </multiselect>
                            </div>
                        </div>
                        <div v-show="false" class="vx-row mb-6" style="width:50%">
                            <div class="vx-col sm:w-1/3 w-full flex items-center">
                                <span>Distribution Channel</span>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <multiselect
                                    class="selectExample"
                                    v-model="customerCategory"
                                    :options="optionCustomerCategory"
                                    :multiple="false"
                                    :allow-empty="false"
                                    :group-select="false"
                                    :max-height="120"
                                    :limit="3"
                                    placeholder="Type to search"
                                    track-by="ID"
                                    label="name"
                                >

                                    <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title">{{ props.option.name }}</span>
                                        </span>
                                    </template>

                                    <template slot="option" slot-scope="props">
                                        <div class="option__desc">
                                            <span class="option__title">{{ props.option.name }}</span>
                                        </div>
                                    </template>

                                </multiselect>
                            </div>
                        </div>
                        <div v-show="false" class="vx-row mb-6" style="width:50%">
                            <div class="vx-col sm:w-1/3 w-full flex items-center">
                                <span>Sales</span>&nbsp;
                                <feather-icon title="Select All" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetSalesFilter()"/>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <multiselect
                                    class="selectExample"
                                    v-model="salesPersonal"
                                    :options="optionSalesPersonal"
                                    :multiple="true"
                                    :allow-empty="false"
                                    :group-select="false"
                                    :max-height="120"
                                    :limit="3"
                                    placeholder="Type to search"
                                    track-by="id"
                                    label="name"
                                >

                                    <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title">{{ props.option.name }}</span>
                                        </span>
                                    </template>

                                    <template slot="option" slot-scope="props">
                                        <div class="option__desc">
                                            <span class="option__title">{{ props.option.name }}</span>
                                        </div>
                                    </template>

                                </multiselect>
                            </div>
                        </div>
                        <div class="vx-row mb-6" style="width:50%">
                            <div class="vx-col sm:w-1/3 w-full flex items-center">
                                <span>SO Date</span>&nbsp;
                                <feather-icon title="Select All" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetDateFilter('date')"/>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <date-range-picker
                                    style="min-height: 40px"
                                    class="w-full"
                                    ref="picker"
                                    opens="center"
                                    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                    :singleDatePicker="false"
                                    :timePicker="false"
                                    :timePicker24Hour="false"
                                    :showWeekNumbers="false"
                                    :showDropdowns="false"
                                    :autoApply="true"
                                    v-model="filterDate"
                                    :linkedCalendars="true"
                                >
                                    <template v-slot:input="picker" style="min-width: 350px">
                                        {{ datePickerDateFormat(picker.startDate) }} -
                                        {{ datePickerDateFormat(picker.endDate) }}
                                    </template>
                                </date-range-picker>
                                <!-- <datepicker
                                    name="date_start"
                                    format="yyyy-MM-dd"
                                    :inline="false"
                                    v-model="soDate"
                                    placeholder="Select Date"
                                    :disabledDates="{
                                        from: dateEnd,
                                    }"
                                ></datepicker> -->
                            </div>
                        </div>
                        <div v-show="false" class="vx-row mb-6" style="width:50%">
                            <div class="vx-col sm:w-1/3 w-full flex items-center">
                                <span>Posting Date</span>&nbsp;
                                <feather-icon title="Select All" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetDateFilter('posting')"/>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <date-range-picker
                                    style="min-height: 40px"
                                    class="w-full"
                                    ref="picker"
                                    opens="center"
                                    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                    :singleDatePicker="false"
                                    :timePicker="false"
                                    :timePicker24Hour="false"
                                    :showWeekNumbers="false"
                                    :showDropdowns="false"
                                    :autoApply="true"
                                    v-model="filterPostingDate"
                                    :linkedCalendars="true"
                                >
                                    <template v-slot:input="picker" style="min-width: 350px">
                                        {{ datePickerDateFormat(picker.startDate) }} -
                                        {{ datePickerDateFormat(picker.endDate) }}
                                    </template>
                                </date-range-picker>
                            </div>
                        </div>
                        <!-- main page -->
                        <div class="vx-row mb-12">
                            <div class="vx-col sm:w-1/1 w-full mb-base">
                                <div class="vx-row mb-6">
                                    <div class="vx-col sm:w-1/6 w-full">
                                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                                            </div>
                                            <vs-dropdown-menu>                
                                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                                    <span>{{item}}</span>
                                                </vs-dropdown-item>
                                            </vs-dropdown-menu>
                                        </vs-dropdown>
                                    </div>
                                    <div class="vx-col sm:w-2/3">
                                        <div class="vx-row">
                                            <vs-input class="ml-4 mt-2 vx-col sm:w-1/3" v-model="filename" placeholder="filename" />
                                            <!-- 
                                            <vs-button v-if="(this.buttonVisible==true)" class="ml-4 mt-2 vx-col sm:w-1/4" color="primary" icon-pack="feather" icon="icon-prin" @click="getData()">Get Data</vs-button>
                                            <vs-button v-if="(this.buttonVisible==false)" class="ml-4 mt-2 vx-col sm:w-1/4" color="primary" icon-pack="feather" icon="icon-prin" @click="downloadExcel()">Download Excel</vs-button> 
                                            -->
                                            <vs-button v-show="false" class="ml-4 mt-2 vx-col sm:w-1/4" color="primary" icon-pack="feather" icon="icon-prin" @click="getData()">Get Data</vs-button>
                                            <vs-button v-if="this.$store.getters['user/hasPermissions']('create')" class="ml-4 mt-2 vx-col sm:w-1/4" color="primary" icon-pack="feather" icon="icon-prin" @click="downloadExcel()">Download Excel</vs-button>
                                        </div>
                                    </div>
                                    <div class="vx-col sm:w-1/6 w-full">
                                        <div class="flex flex-wrap items-center justify-between">
                                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : SO Code or Customer Name" />
                                        </div>
                                    </div>
                                </div>

                                <vs-table :data="dataSodogibill" stripe border>
                                    <template slot="thead">
                                        <vs-th>Sales Office</vs-th>
                                        <vs-th>Sales Office Desc</vs-th>
                                        <vs-th>Sales Order No.</vs-th> <!-- new -->
                                        <vs-th>Ref PO Code</vs-th> <!-- new -->
                                        <vs-th>Sold To Party</vs-th>
                                        <vs-th>Sold To Party Name</vs-th>
                                        <vs-th>Sold To Party Name 2</vs-th> <!-- new -->
                                        <vs-th>Ship To Party</vs-th> <!-- new -->
                                        <vs-th>Ship To Party Name</vs-th> <!-- new -->
                                        <vs-th>Ship To Party Name 2</vs-th> <!-- new -->
                                        <vs-th>Street</vs-th> <!-- new -->
                                        <vs-th>Dist Channel</vs-th>
                                        <vs-th>CG1 Code</vs-th> <!-- new -->
                                        <vs-th>CG1 Desc</vs-th> <!-- new -->
                                        <vs-th>CG2 Code</vs-th> <!-- new -->
                                        <vs-th>CG2 Desc</vs-th> <!-- new -->
                                        <vs-th>CG3 Code</vs-th> <!-- new -->
                                        <vs-th>CG3 Desc</vs-th> <!-- new -->
                                        <vs-th>Pricing Group</vs-th> <!-- new -->
                                        <vs-th>Pricing Group Desc</vs-th> <!-- new -->
                                        <vs-th>Order Type</vs-th>
                                        <vs-th>Description</vs-th> <!-- new -->
                                        <vs-th>PO Number</vs-th>
                                        <vs-th>PO Doc Date</vs-th>
                                        <vs-th>SO Number</vs-th>
                                        <vs-th>SO Doc Date</vs-th>
                                        <vs-th>SO Creation Date</vs-th>
                                        <vs-th>SO Creation Time</vs-th> <!-- new -->
                                        <vs-th>SO Created By</vs-th> <!-- new -->
                                        <vs-th>RDD (Req. Delivery Date)</vs-th> <!-- new -->
                                        <vs-th>Reject Desc.</vs-th> <!-- new -->
                                        <vs-th>SO Status</vs-th> <!-- new -->
                                        <vs-th>SO GSV Amt</vs-th>
                                        <vs-th>SO Disc Amt</vs-th>
                                        <vs-th>SO DPP Amt</vs-th>
                                        <vs-th>DO Number</vs-th>
                                        <vs-th>DO DPP Amt</vs-th>
                                        <vs-th>DO Creation Date</vs-th>
                                        <vs-th>DO Creation Time</vs-th> <!-- new -->
                                        <vs-th>GI Number</vs-th>
                                        <vs-th>Actual GI Date</vs-th>
                                        <vs-th>Posting Date</vs-th>
                                        <vs-th>GI DPP Amt</vs-th>
                                        <vs-th>Handover Code</vs-th> <!-- new -->
                                        <vs-th>Handover Date</vs-th> <!-- new -->
                                        <vs-th>Handover Creation Date</vs-th> <!-- new -->
                                        <vs-th>Handover Creation Time</vs-th> <!-- new -->
                                        <vs-th>POD No.</vs-th> <!-- new -->
                                        <vs-th>POD Date</vs-th> <!-- new -->
                                        <vs-th>POD Creation Date</vs-th> <!-- new -->
                                        <vs-th>POD Creation Time</vs-th> <!-- new -->
                                        <vs-th>POD Status</vs-th> <!-- new -->
                                        <vs-th>Billing Number</vs-th>
                                        <vs-th>Billing Date</vs-th>
                                        <vs-th>Billing GSV Amt</vs-th>
                                        <vs-th>Billing Disc Amt</vs-th>
                                        <vs-th>Billing DPP Amt</vs-th>
                                        <vs-th>Bill. Creation Date</vs-th> <!-- new -->
                                        <vs-th>Bill. Creation Time</vs-th> <!-- new -->
                                        <vs-th>BP Code</vs-th> <!-- new -->
                                        <vs-th>Item Category</vs-th> <!-- new -->
                                        <vs-th>Material Code</vs-th> <!-- new -->
                                        <vs-th>Material Desc.</vs-th> <!-- new -->
                                        <vs-th>Batch No.</vs-th> <!-- new -->
                                        <vs-th>SO HU</vs-th> <!-- new -->
                                        <vs-th>SO Qty.</vs-th> <!-- new -->
                                        <vs-th>SO Unit Price</vs-th> <!-- new -->
                                        <vs-th>SO Amount</vs-th> <!-- new -->
                                        <vs-th>SO Volume Unit</vs-th> <!-- new -->
                                        <vs-th>SO Volume</vs-th> <!-- new -->
                                        <vs-th>PI HU</vs-th> <!-- new -->
                                        <vs-th>PI Qty.</vs-th> <!-- new -->
                                        <vs-th>PI Amount</vs-th> <!-- new -->
                                        <vs-th>POD HU</vs-th> <!-- new -->
                                        <vs-th>POD Qty</vs-th> <!-- new -->
                                        <vs-th>POD Amount</vs-th> <!-- new -->
                                        <vs-th>POD Volume Unit</vs-th> <!-- new -->
                                        <vs-th>POD Volume</vs-th> <!-- new -->
                                        <vs-th>Bill Unit</vs-th> <!-- new -->
                                        <vs-th>Bill Qty</vs-th> <!-- new -->
                                        <vs-th>Bill Amount</vs-th> <!-- new -->
                                        <vs-th>Bill Vol. Unit</vs-th> <!-- new -->
                                        <vs-th>Bill Volume</vs-th> <!-- new -->
                                        <vs-th>Gross Up 1%</vs-th> <!-- new -->
                                        <vs-th>Incoterm</vs-th> <!-- new -->
                                        <vs-th>Mat. Group 1</vs-th> <!-- new -->
                                        <vs-th>Mat. Gr1 Desc</vs-th> <!-- new -->
                                        <vs-th>Mat. Group 2</vs-th> <!-- new -->
                                        <vs-th>Mat. Gr2 Desc</vs-th> <!-- new -->
                                        <vs-th>Mat. Group 3</vs-th> <!-- new -->
                                        <vs-th>Mat. Gr3 Desc</vs-th> <!-- new -->
                                        <vs-th>Mat. Group 4</vs-th> <!-- new -->
                                        <vs-th>Mat. Gr4 Desc</vs-th> <!-- new -->
                                        <vs-th>Mat. Group 5</vs-th> <!-- new -->
                                        <vs-th>Mat. Gr5 Desc</vs-th> <!-- new -->
                                        <vs-th>Div Code</vs-th> <!-- new -->
                                        <vs-th>Div. Desc.</vs-th> <!-- new -->
                                        <vs-th>Salesman Code</vs-th> <!-- new -->
                                        <vs-th>Salesman Name</vs-th> <!-- new -->
                                        <vs-th>Salesman Type</vs-th> <!-- new -->
                                        <vs-th>Updated By</vs-th>
                                    </template>

                                    <template slot-scope="{data}">
                                        <vs-tr :key="idx" v-for="(dt, idx) in data">
                                            <vs-td>{{dt.sales_office}}</vs-td>
                                            <vs-td>{{dt.sales_office_desc}}</vs-td>
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Sales Order No."}}</vs-td> -->
                                            <vs-td>{{dt.po_code}}</vs-td>
                                            <vs-td>{{dt.sold_to_party}}</vs-td>
                                            <vs-td>{{dt.sold_to_party_name}}</vs-td>
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Sold To party 2"}}</vs-td> -->
                                            <vs-td>{{dt.ship_to_party}}</vs-td>
                                            <vs-td>{{dt.sold_to_party_name}}</vs-td>
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Ship To Name 2"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Street"}}</vs-td> -->
                                            <vs-td>{{dt.cc_name}}</vs-td>
                                            <vs-td>{{dt.cg1_code}}</vs-td>
                                            <vs-td>{{dt.cg1_name}}</vs-td>
                                            <vs-td>{{dt.cg2_code}}</vs-td>
                                            <vs-td>{{dt.cg2_name}}</vs-td>
                                            <vs-td>{{dt.cg3_code}}</vs-td>
                                            <vs-td>{{dt.cg3_name}}</vs-td>
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Pricing Group Code"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Pricing Group Name"}}</vs-td> -->
                                            <vs-td>{{dt.so_type}}</vs-td>
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Description / Note"}}</vs-td> -->
                                            <vs-td>{{dt.po_code}}</vs-td>
                                            <vs-td>{{ " - " }}</vs-td> <!-- <vs-td>{{ "dt. PO Date" }}</vs-td> -->
                                            <vs-td>{{dt.oms_code}}</vs-td>
                                            <vs-td>{{dateFormat(dt.so_date)}}</vs-td>
                                            <vs-td>{{(dt.so_created_at) ? dateFormat(dt.so_created_at) : '-'}}</vs-td>
                                            <vs-td>{{(dt.so_created_at) ? dateFormat(dt.so_created_at) : '-'}}</vs-td>
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. SO created by"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. RDD (Req Deliv Date)"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Reject Desc."}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. SO Status"}}</vs-td> -->
                                            <vs-td align="right">{{formatCurrency(dt.gsv)}}</vs-td>
                                            <vs-td align="right">{{formatCurrency(dt.so_disc)}}</vs-td>
                                            <vs-td align="right">{{formatCurrency(dt.so_dpp)}}</vs-td>
                                            <vs-td>{{(dt.do_code) ? dt.do_code : '-'}}</vs-td>
                                            <vs-td align="right">{{dt.do_code ? formatCurrency(dt.dpp) : ''}}</vs-td> <!-- <vs-td>{{"dt. DO DPP Amount"}}</vs-td> -->
                                            <vs-td>{{(dt.do_code) ? dateFormat(dt.delivery_order_date) : '-'}}</vs-td>
                                            <vs-td>{{ (dt.do_code) ? dateFormat(dt.do_created_at) : '-'}}</vs-td>
                                            <vs-td>{{(dt.gi_number) ? dt.gi_number : '-'}}</vs-td>
                                            <vs-td>{{(dt.gi_number) ? dateFormat(dt.gi_created_at) : '-'}}</vs-td>
                                            <vs-td>{{(dt.posting_date) ? dateFormat(dt.posting_date) : '-'}}</vs-td>
                                            <vs-td align="right">{{dt.gi_code ? formatCurrency(dt.cogs) : ''}}</vs-td>
                                            <!--  -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Handover Code"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Handover Date"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Handover Creation Date"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Handover Creation Time"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. POD Number"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. POD Date"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. POD Creation Date"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. POD Creation Time"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. POD Status"}}</vs-td> -->
                                            <vs-td>{{(dt.invoice_code) ? dt.invoice_code : '-'}}</vs-td>
                                            <vs-td>{{(dt.invoice_code) ? dateFormat(dt.invoice_date) : '-'}}</vs-td>
                                            <vs-td align="right">{{dt.invoice_code ? formatCurrency(dt.gsv) : ''}}</vs-td>
                                            <vs-td align="right">{{dt.invoice_code ? formatCurrency(dt.discount) : ''}}</vs-td>
                                            <vs-td align="right">{{dt.invoice_code ? formatCurrency(dt.dpp) : ''}}</vs-td>
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Billing Creation Date"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Billing Creation Time"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. BP Code"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Item Category"}}</vs-td> -->
                                            <vs-td>{{dt.material_code}}</vs-td>
                                            <vs-td>{{dt.material_desc}}</vs-td>
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Batch No."}}</vs-td> -->
                                            <vs-td>{{dt.so_unit_uom}}</vs-td>
                                            <vs-td>{{dt.so_qty_uom}}</vs-td>
                                            <vs-td>{{dt.unit_price}}</vs-td>
                                            <vs-td>{{dt.so_amount}}</vs-td>
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. SO Volume Unit"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. SO Volume"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. PI HU"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. PI Qty."}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. PI Amount"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. POD HU"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. POD Qty"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. POD Amount"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. POD Volume Unit"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. POD Volume"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Bill Unit"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Bill Qty"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Bill Amount"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Bill Vol. Unit"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Bill Volume"}}</vs-td> -->
                                            <vs-td>{{dt.gross_up}}</vs-td>
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Incoterm"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Mat Group 1"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. MatGr1 Desc"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Mat Group 2"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. MatGr2 Desc"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Mat Group 3"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. MatGr3 Desc"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Mat Group 4"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. MatGr4 Desc"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Mat Group 5"}}</vs-td> -->
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. MatGr5 Desc"}}</vs-td> -->
                                            <vs-td>{{dt.div_code}}</vs-td>
                                            <vs-td>{{dt.div_desc}}</vs-td>
                                            <vs-td>{{dt.sales_code_desc}}</vs-td>
                                            <vs-td>{{dt.sales_code}}</vs-td>
                                            <vs-td>{{" - "}}</vs-td> <!-- <vs-td>{{"dt. Salesman Type"}}</vs-td> -->
                                            <vs-td>{{dt.sales_code}}</vs-td>
                                        </vs-tr>
                                    </template>
                                </vs-table>
                        
                                <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                            </div>
                        </div>
                    </vs-tab>
                    <vs-tab label="Export">
                        <div class="tab-text">
                            <data-table-export></data-table-export>
                        </div>
                    </vs-tab>
                </vs-tabs>
            </div>
        </div>
    </vx-card>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import DataTableExport from "./DataTableExport.vue";
import Datepicker from "vuejs-datepicker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
    components: {
        DateRangePicker,
        Datepicker,
        DataTableExport
    },
    mounted() {
        this.getOptionOU();
        // this.getOptionCustomerCategory();
        // this.getOptionSalesPersonal();
        
        const today = new Date();
        // this.filterDate.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        // this.filterDate.endDate = new Date(today.getFullYear(), today.getMonth()+1, 0);
        this.filterDate.startDate = new Date(today);
        this.filterDate.endDate = new Date(today);
        this.filterPostingDate.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        this.filterPostingDate.endDate = new Date(today.getFullYear(), today.getMonth()+1, 0);
        this.generateFilename();
        this.$store.dispatch("user/getPermissions","report-zdrsales");
    },
    computed: {
        watchedProperties() {
            return `${this.operatingUnit.length}||${this.customerCategory.ID}||${this.search}||${this.salesPersonal.length}||${this.filterDate.startDate}||${this.filterDate.endDate}||${this.filterPostingDate.startDate}||${this.filterPostingDate.endDate}`;
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                if (!this.buttonVisible) {
                    this.page(page);         
                }       
            }
        }
    },
    data: () => ({
        tabColor:['success', 'primary', 'warning', 'info', 'success', 'rgb(62, 201, 214)'],
        tabIndex: 0,
        buttonVisible: false,
        salesChannel: {},
        customerCategory: {ID:"", name:""},
        optionCustomerCategory:[{}],
        salesPersonal: [],
        optionSalesPersonal:[{}],
        colorx: "success",
        operatingUnit: [],
        optionOU: [{
            OperatingUnitTerritory: {
                Territory: {
                    code: ""
                }
            }
        }],
        soDate: new Date(),
        dateEnd: new Date(),
        filterDate: {
            startDate: null,
            endDate: null,
        },
        filterPostingDate: {
            startDate: null,
            endDate: null,
        },
        dataSodogibill: [],
        limits: [10, 25, 50, 100, "All"],
        limitShow: 10,
        drawData: 0,
        isActive: 1,
        search: "",
        recordsTotal: 0,
        maxPage: 2,
        dataEnd: 0,
        filename: "",
    }),
    watch: {
        watchedProperties: function() {
            this.buttonVisible = true
            // this.getData();
        },
        filterDate: function() {
            console.log("this.filterDate", this.filterDate);
            this.generateFilename();
        },
    },
    methods: {
        datePickerDateFormat(date) {
            let a = null;
            if (date != null) {
                a = moment(date).format("dddd, MMMM Do YYYY");
            }
            return a;
        },
        resetTerritoryFilter() {
            this.operatingUnit = [];
        },
        resetSalesFilter() {
            this.salesPersonal = [];
        },
        resetDateFilter(name) {
            if(name=="date") {
                this.filterDate.startDate = null;
                this.filterDate.endDate = null;
            } else if(name=="posting") {
                this.filterPostingDate.startDate = null;
                this.filterPostingDate.endDate = null;
            }
        },
        getOptionOU() {
            // props.option.OperatingUnitTerritory.Territory.code
            console.log("this.$userLogin", this.$userLogin)
            this.$http.get("/api/v1/master/operating-unit-territory").then(
                resp => {
                    if(resp.code == 200){
                        this.optionOU = []
                        // this.optionOU.push({ID: 0, Name:'All'})
                        let data = resp.data.records
                        for(var idx = 0;idx<data.length;idx++){
                            if(this.$userLogin.territory_id == 0){
                                this.optionOU.push({
                                    CodeOu: data[idx].Territory.code,
                                    CodeOuTerr: data[idx].Territory.code,
                                    CodeTerr: data[idx].Territory.code,
                                    ID: data[idx].Territory.ID,
                                    IDTerr: data[idx].Territory.ID, code: data[idx].Territory.Code,
                                    SelectName: " (" + data[idx].Territory.code + ") " + data[idx].Territory.name,
                                    Name: " (" + data[idx].Territory.code + ") " + data[idx].Territory.name
                                })
                            } else {
                                if (data[idx].Territory.ID == this.$userLogin.territory_id) {
                                    this.optionOU.push({
                                        CodeOu: data[idx].Territory.code,
                                        CodeOuTerr: data[idx].Territory.code,
                                        CodeTerr: data[idx].Territory.code,
                                        ID: data[idx].Territory.ID,
                                        IDTerr: data[idx].Territory.ID, code: data[idx].Territory.Code,
                                        SelectName: " (" + data[idx].Territory.code + ") " + data[idx].Territory.name,
                                        Name: " (" + data[idx].Territory.code + ") " + data[idx].Territory.name
                                    })
                                }
                            }
                            //this.optionOU.push(data[idx]);

                        }
                        this.operatingUnit = [this.optionOU[0]];
                    } else {
                        // 
                    }
                }
            );
        },
        getOptionCustomerCategory() {
            this.$http.get("/api/v1/master/customer-category-all").then(
                resp => {
                    if(resp.code == 200){
                        this.optionCustomerCategory = []
                        this.optionCustomerCategory.push({name:'All'})
                        for(var customerCategory = 0;customerCategory<resp.data.length;customerCategory++){
                            this.optionCustomerCategory.push(resp.data[customerCategory])
                        }
                        this.customerCategory = this.optionCustomerCategory[0];
                    }else{
                        // this.$vs.loading.close();
                        this.$router.push("/");
                    }
                }
            );
        },
        getOptionSalesPersonal() {
            this.$http.get("/api/v1/sales-order/sales-personal").then(
                resp => {
                    if(resp.code == 200){
                        this.optionSalesPersonal = []
                        // this.optionSalesPersonal.push({id: 0, name:'All'})
                        let data = resp.data.SalesPersonalSO
                        for(var idx = 0;idx<data.length;idx++){
                            this.optionSalesPersonal.push(data[idx])
                        }
                        this.salesPersonal = [this.optionSalesPersonal[0]];
                    } else {
                        // this.$vs.loading.close();
                        // this.$router.push("/");
                    }
                }
            );
        },
        page(page) {
            this.getData(page);
            return page;
        },

        dateFormat(date) {
            if (date) {
                return moment(String(date)).format('DD/MM/YYYY')
            }
        },

        formatCurrency(nominal) {
            if(nominal) { // nominal > 0 untuk handle return NaN
                if(nominal > 0) {
                    nominal = (nominal/1).toFixed(2).replace('.', ',');
                    return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    nominal = nominal * -1
                    nominal = (nominal/1).toFixed(2).replace('.', ',');
                    return "-"+nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            } else {
                return 0;
            }
        },

        changeLimit(limit) {
            this.limitShow = limit;
            this.getData(this.isActive);
        },

        async downloadExcel() {
            if(this.recordsTotal <= 0) {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure want to download before preview data ?",
                    accept: this.doDownloadExcel,
                });
            } else {
                this.doDownloadExcel();
            }
        },

        async doDownloadExcel() {
            // Prevent download sebelum getData
            // if(this.recordsTotal <= 0) {
            //     this.$vs.notify({
            //         color: "warning",
            //         title: "Data not found",
            //         text: "Can't generating data",
            //         position: "top-right",
            //         iconPack: "feather",
            //         icon: "icon-x-circle"
            //     });
            //     return true;
            // }

            this.$vs.notify({
                color: "primary",
                title: "Processing . . . .",
                text: "Generating data",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
            });

            var arrTerritoryID = []
            for(var i = 0;i<this.operatingUnit.length;i++){
                arrTerritoryID.push(this.operatingUnit[i].ID)
            }

            var arrSalesPersonalID = []
            for(var j = 0;j<this.salesPersonal.length;j++){
                arrSalesPersonalID.push(this.salesPersonal[j].id)
            }

            var parameter = {
                title: this.filename,
                territory_id: arrTerritoryID,
                customer_category_id: null,//this.customerCategory.ID,
                sales_personal_id: null,//arrSalesPersonalID,
                search: this.search,
                length: 1000000,
                offset: 0,
                start_date: this.filterDate.startDate != null ? moment(this.filterDate.startDate).format("YYYY-MM-DD") : null,
                end_date: this.filterDate.endDate != null ? moment(this.filterDate.endDate).format("YYYY-MM-DD") : null,
                start_posting_date: null,//this.filterPostingDate.startDate != null ? moment(this.filterPostingDate.startDate).format("YYYY-MM-DD") : null,
                end_posting_date: null,//this.filterPostingDate.endDate != null ? moment(this.filterPostingDate.endDate).format("YYYY-MM-DD") : null,
                so_date: null //this.soDate != null ? moment(this.soDate).format("YYYY-MM-DD") : null,
            };

            if(this.progressRestriction == false) {
                parameter.approval_status = 0;
                parameter.approval_manager = 0;
                parameter.approval_release = 0;
                parameter.validation_status = 0;
                parameter.allocation_status = 0;
            }


            this.$http.get("/api/v1/report/zdr-sales/export", {
                params: parameter,
            }).then((resp) => {
                this.$vs.loading.close();
                if (resp.status == "error") {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                } else {
                    this.$vs.notify({
                        color: "success",
                        title: "Processing",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            });
        },
        randomString(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        generateFilename() {
            this.randomString(6)
            let start = moment(this.filterDate.startDate).format("YYYYMMDD")
            let end = moment(this.filterDate.endDate).format("YYYYMMDD")
            console.log("this.filterDate.startDate", start);
            console.log("this.filterDate.endDate", end);
            this.filename = "ZDRSales_"+this.randomString(6)+"_"+start+"_"+end
            console.log("this.filename", this.filename)
        },

        getData(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.buttonVisible = false

            var arrTerritoryID = []
            for(var i = 0;i<this.operatingUnit.length;i++){
                arrTerritoryID.push(this.operatingUnit[i].ID)
            }

            var arrSalesPersonalID = []
            for(var j = 0;j<this.salesPersonal.length;j++){
                arrSalesPersonalID.push(this.salesPersonal[j].id)
            }

            var parameter = {
                title: this.filename,
                territory_id: arrTerritoryID,
                customer_category_id: this.customerCategory.ID,
                sales_personal_id: arrSalesPersonalID,
                search: this.search,
                limit: this.limitShow,
                offset: this.limitShow * (page - 1),
                page: page,
                so_date: this.soDate != null ? moment(this.soDate).format("YYYY-MM-DD") : null,
                start_date: null,// sementara dibatasi --this.filterDate.startDate != null ? moment(this.filterDate.startDate).format("YYYY-MM-DD") : null,
                end_date: null, // sementara dibatasi --this.filterDate.endDate != null ? moment(this.filterDate.endDate).format("YYYY-MM-DD") : null,
                start_posting_date: null, // sementara dibatasi --this.filterPostingDate.startDate != null ? moment(this.filterPostingDate.startDate).format("YYYY-MM-DD") : null,
                end_posting_date: null, // sementara dibatasi --this.filterPostingDate.endDate != null ? moment(this.filterPostingDate.endDate).format("YYYY-MM-DD") : null,
            };

            if(this.progressRestriction == false) {
                parameter.approval_status = 0;
                parameter.approval_manager = 0;
                parameter.approval_release = 0;
                parameter.validation_status = 0;
                parameter.allocation_status = 0;
            }

            // this.$http.get("/api/v1/sales-order", {
            // this.$http.get("/api/v1/report/so-do-gi-bill", {
            this.$http.get("/api/v1/report/zdr-sales", {
                params: parameter
            }).then(resp => {
                if (resp.code == 200) {
                    this.drawData = this.drawData + 1;
                    this.dataSodogibill = resp.data.data;
                    this.recordsTotal = resp.data.recordsTotal;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.length;
                    this.checkedAll = false;
                    this.$vs.loading.close();

                    this.generateFilename();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            });
        },
    }
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>