import api from "../../axios";

export const personalUser = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/setting/user-personal")
      .then(r => {
        resolve(r);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const personalUserMenu = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/menu")
      .then(r => {
        resolve(r);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const permission = modul => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/permission", {
        params: {
          module: modul,
          source: "OMS"
        }
      })
      .then(r => {
        resolve(r);
      })
      .catch(e => {
        reject(e);
      });
  });
};
